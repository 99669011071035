import React, { useState } from "react"
import { graphql } from "gatsby"

// // import styled from "@emotion/styled"
// import { Box } from "@chakra-ui/core"

// import Masonry from "containers/masonry"
// import Layout from "containers/layout"
// import SEO from "components/seo"
// import ForschungsItem from "containers/forschung"
// // import ZurueckLink from "components/zurueckLink"
// import Lightbox from "containers/lightbox"
// import { onlyClient } from "./index"

import { FreundeskreisTempl } from "../pageTemplates/freundeskreis"

const Freundeskreis = ({ data, location }) => (
  <FreundeskreisTempl data={data} location={location} />
)

export const query = graphql`
  query {
    strapi {
      freundeskrei {
        meta {
          title
          description
        }
        inhalt {
          __typename
          ... on STRAPI_ComponentPageFreund {
            freund {
              id
              author
              titel
              untertitel
              text
              lightbox
              openDefault
              gallery {
                url
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageArticle {
            id
            article {
              text
              title
              subtitle
              use_alternative_font
              cover {
                __typename
                url
                caption
                alternativeText
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
              gallery {
                url
                caption
                alternativeText
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageInformation {
            id
            text
            title
            __typename
            image {
              url
              caption
              alternativeText
              imageFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageText {
            id
            text
            __typename
          }
        }
        inhalt_en {
          __typename
          ... on STRAPI_ComponentPageFreund {
            freund {
              id
              author
              titel
              untertitel
              text
              lightbox
              openDefault
              gallery {
                url
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageInformation {
            id
            text
            title
            __typename
            image {
              url
              caption
              alternativeText
              imageFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentPageText {
            id
            text
            __typename
          }
          ... on STRAPI_ComponentPageArticle {
            id
            article {
              text
              title
              subtitle
              use_alternative_font
              cover {
                __typename
                url
                caption
                alternativeText
                imageFileGray: imageFile {
                  childImageSharp {
                    fluid(
                      duotone: { highlight: "#ffffff", shadow: "#007c60" }
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
              gallery {
                url
                caption
                alternativeText
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Freundeskreis
